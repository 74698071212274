import './App.css';
import PatientList from './views/pateintlist';
import Login from './views/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PublicRoute from 'routes/publicRoute';
import ProtectedRoute from 'routes/privateRoute';
import './assets/css/style.css'
import { Toaster } from "react-hot-toast";
import Loader from "services/loader";
import { LoaderProvider } from "services/loaderService";
import VisitList from 'views/Visit';
import AttorneySign from 'views/AttorneySign';

function App() {
  return (
    <>
      <LoaderProvider>
        <Toaster
          position="top-right"
          toastOptions={{ style: { borderRadius: "0.125rem" }, duration: 3000 }}
        />
        <Loader />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/patientList"
              element={
                <ProtectedRoute>
                  <PatientList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visitList"
              element={
                <ProtectedRoute>
                  <VisitList />
                </ProtectedRoute>
              }
            />
            <Route
            path="/attorneySign"
            element={
              <PublicRoute>
                <AttorneySign />
              </PublicRoute>
            }
          />
          </Routes>
        </Router>
      </LoaderProvider>
    </>
  );
}

export default App;
