import React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SignatureComponent from "shared/DoctorSign";
import { GetMedicallLien, SaveMedicallLien } from "services/http/PatientList";
import { ValidateAttorneyRequestToken } from "services/http/AttorneySign";
import { useLoader } from "services/loaderService";
import { Link , useLocation  } from 'react-router-dom';

const AttorneySign = () => {

  const location = useLocation();
    // Extract the token from the URL
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");


  const [showSign, setShowSign] = useState(false);
  const [collectionOfFormData, setCollectionOfFormData] = useState([]);
  const [patientInfo, setPatientInfo] = useState([]);
  const { showLoader, hideLoader } = useLoader();
  const [attorneySignImg, setAttorneySignImg] = useState("");
  const [caseFormId, setCaseFormId] = useState("");
  const [validateAttorneyData, setValidateAttorneyData] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  useEffect(() => {
         ValidateToken(); // Wait for ValidateToken to complete
  }, []);
  

  const ValidateToken = async () => {
    showLoader();
    try {
      const result = await ValidateAttorneyRequestToken(token);
      if (result) {
        if (result?.HasException) {
          toast.error(result?.MessageText);
        } else {
          setValidateAttorneyData(result?.Data[0]);
          if (result?.Data[0]?.AttorneyId !== null) {
            getMedicalLienData(result?.Data[0]); // Call getMedicalLienData after ValidateToken
          }else{
            setShowSuccessMessage(true)
            hideLoader();
          }
        }
      } else {
        toast.error("Something went wrong!");
        hideLoader();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
      hideLoader();
    }
  };

  const GragIntakeSheetData = (hippaAuthData) => {
    let row = [];
    for (let i = 0; i < hippaAuthData?.notesdata.length; i++) {
      if (hippaAuthData?.notesdata[i].ApplicationValue) {
        for (
          let j = 0;
          j < hippaAuthData.notesdata[i].ApplicationValue.length;
          j++
        ) {
          let data = {};
          data.Name = hippaAuthData.notesdata[i].ApplicationValue[j].Name;
          data.Type = hippaAuthData.notesdata[i].ApplicationValue[j].Type;
          data.Value = hippaAuthData.notesdata[i].ApplicationValue[j].Value;
          data.isRender =
            hippaAuthData.notesdata[i].ApplicationValue[j].isRender;
          data.RenderKeyName = hippaAuthData.notesdata[i].RenderKeyName;
          row.push(data);
        }
      }
    }
    return row;
  };

  const closeSignPad = () => {
    setShowSign(false);
  };

  const onSubmit = async () => {
    if(!attorneySignImg){
      toast.error("Attorney sign is missing !!!");
      return
    }
    showLoader();
    let result = "";

    let attorneyName = patientInfo?.AttorneyName ? patientInfo?.AttorneyName : validateAttorneyData?.AttorneyName
    let attorneyAddress =  patientInfo?.AttorneyAddress ? patientInfo?.AttorneyAddress : validateAttorneyData?.AttorneyAddress
 
    const base64String = attorneySignImg.replace("data:image/png;base64,", "");
    const updatedData = collectionOfFormData?.notesdata.map((item) => {
      if (item.RenderKeyName === "AttorneyInfo") {
        return {
          ...item,
          ApplicationValue: item.ApplicationValue.map((subItem) => {
            if (subItem.Name === "AttorneySign") {
              return { ...subItem, Value: base64String };
            }
            if (subItem.Name === "AttorneyName") {
              return { ...subItem, Value: attorneyName };
            }
            if (subItem.Name === "AttorneyAddress") {
              return { ...subItem, Value: attorneyAddress };
            }
            return subItem;
          }),
        };
      }
    
      return item;
    });
    let notesData = { notesdata: updatedData };
    const jsonString = JSON.stringify(notesData); // Pretty-printed with 2 spaces
    setCollectionOfFormData(notesData);

    const payload = {
      IsCopySign: false,
      CaseId: validateAttorneyData?.CaseId,
      CaseFormDataId: caseFormId,
      UserId: validateAttorneyData?.UserId,
      IsFormCompleted: false,
      FormId: 6,
      EntityLevel: 0,
      TemplateName: null,
      CompanyId: validateAttorneyData?.CompanyId,
      DoctorId: "ALL",
      FormName: "Medical LIEN",
      FormType: null,
      JsonData: jsonString,
    };
    try {
      result = await SaveMedicallLien(payload);
      if (result) {
        hideLoader();
        ValidateToken();
        var file = new Blob([result], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.response?.MessageText || "Something went wrong!");
      console.log(error);
      hideLoader();
    }
  };

  const onSave = async (attorneySign) => {
    setAttorneySignImg(attorneySign);
    setShowSign(false); // Open the signature pad
  };

  const getMedicalLienData = async (AttorneyData) => {
    showLoader();
    const payload = {
      CaseFormDataId: null,
      CompanyId: AttorneyData?.CompanyId,
      DoctorId: "ALL",
      FormName: "Medical LIEN",
      FormId: 6,
      TemplateName: null,
      EntityLevel: 0,
      FormType: null,
      IsFormCompleted: false,
      JsonData: null,
      CaseId: AttorneyData?.CaseId,
      UserId: AttorneyData?.UserId,
    };
    try {
      let result = await GetMedicallLien(payload);
      if (result != null) {
        if (result?.HasException === false) {
          let formData = JSON.parse(result.Data[0].JsonData);
          setCollectionOfFormData(formData);
          let collection = GragIntakeSheetData(formData);
          setCaseFormId(result.Data[0]?.CaseFormDataId);
          const resultt = collection.reduce((acc, item) => {
            acc[item.Name] = item.Value || ""; // Default to an empty string if Value is undefined
            return acc;
          }, {});
          setPatientInfo(resultt);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.MessageText || "Something went wrong!");
        }
      } else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.response?.MessageText || "Something went wrong!");
      console.log(error);
    }
  };

  const handleOpenSignaturePad = (e) => {
    e.stopPropagation(); // Prevent other click events
    setShowSign(true); // Open the signature pad
  };
  return (
    <>
      <nav className="sticky top-0 z-10 bg-lightblue">
          {/* Parent container for centering */}
          <div className="flex justify-center items-center h-12">
              {/* Logo Section */}
              <div className="flex justify-center items-center text-center">
                  <Link className="logo-horizontal h-[42px]" to="/">
                    <img
                        src={require("../../assets/image/brand/logo.png")}
                        className="pb-1 h-10 w-auto sm:h-12 sm:w-auto header-brand-img desktop-logo"
                        alt="logo"
                    />
                  </Link>
              </div>
          </div>
      </nav>

       {showSuccessMessage ?
        <> 
          <div className="flex items-center justify-center text-center text-cadetblue text-2xl sm:text-3xl md:text-4xl lg:text-5xl mt-48">
          {validateAttorneyData?.Message}
          </div>
          <div className={'w-full text-center text-gray-600 text-sm py-1'} style={{position : "absolute", bottom:0}}>
            Copyright © {new Date().getFullYear()} GYB All Rights Reserved.
          </div>
        </>
       : <>
     
      <div className="text-center text-xl font-bold mt-4 mb-4">Medical Lien</div>
      <div className="mx-auto px-8">
        <form>
          <div className="space-y-1">
            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="text-sm/6 font-bold text-gray-900 lg:w-2/6"
              >
                Know all man by those present that I:
              </label>
              <input
                type="text"
                className="mt-1 border rounded px-3 py-2 cursor-not-allowed w-4/5 name-custom-class lg:w-4/6"
                value={patientInfo?.PatientName1}
                disabled
              />
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                Residing at:
              </label>
              <input
                type="text"
                className="mt-1 border rounded px-3 py-2 cursor-not-allowed md:w-4/5 lg:w-4/5 w-full"
                value={patientInfo?.PatientAddress}
                disabled
              />
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                {" "}
                For and in consideration of medical care and services tendered
                and hereafter and rendered to me by Dr.
              </label>
              <input
                type="text"
                className="mt-1 w-1/3 border rounded px-3 py-2 custom-dr1-class"
                // onChange={(e) => this.setState({ considerationOfMedicalCareServiceTendered: e.target.value })}
                value={patientInfo?.PhysicianName1}
                disabled
              />
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                Beginning with the
              </label>
              <input
                type="text"
                className="border rounded px-3 py-2 w-1/4 custom-BeginingDate-class"
                value={patientInfo?.BeginingDate}
                // onChange={(e) => this.setState({ beginingWithMeDate: e.target.value })}
                disabled
              />
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                Do hereby assign, transfer and set over unto Dr.
              </label>
              <input
                type="text"
                className="mt-1 w-1/3 border rounded px-3 py-2 custom-dr-class"
                // onChange={(e) => this.setState({ assignTransferSetOverDr: e.target.value })}
                value={patientInfo?.PhysicianName2}
                disabled
              />
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                such part of any about:
              </label>
              <input
                type="text"
                className="mt-1 w-3/4 border rounded px-3 py-2 custom-about-text"
                // onChange={(e) => this.setState({ partOfAnyAbout: e.target.value })}
                value={patientInfo?.AboutText}
                disabled
              />
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <div
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold text-justify"
              >
                As is necessary to pay in full said medical services and I
                further consent, agree and stipulate that said medical-fees
                and/or charges shall constitute a primary lien which may be
                placed or charged against such judgment, settlement, funds or
                proceeds of any such claim or action occurred and/or obtained as
                a result of any such claim or action I may have regardless of
                who may be in possession of such funds of proceeds. I hereby
                authorize and direct such person or persons, party of parties,
                firm or firms, corporation or corporations who will or may be or
                become indebted to me as a result thereof to pay over in the
              </div>
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                amount of $
              </label>{" "}
              <input
                type="text"
                className="border rounded px-3 py-2"
                // onChange={(e) => this.setState({ amount: e.target.value })}
                value={patientInfo?.Amount}
                disabled
              />{" "}
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                to the Dr.
              </label>{" "}
              <input
                type="text"
                className="mt-1 w-1/3 border rounded px-3 py-2 lower-dr-class"
                // onChange={(e) => this.setState({ toTheDr: e.target.value })}
                value={patientInfo?.PhysicianName3}
                disabled
              />{" "}
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                {" "}
                or their assignee for such medical services,
              </label>
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <div
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold text-justify"
              >
                without further notice to such parties from me and I agree
                to-hold such parties harmless on account of any such payments
                made. I hereby authorize and direct my attorney or attorneys or
                any other person or persons unto whose hands or possession any
                of the said funds, proceeds or monies shall come, to hold in
                trust and pay over to the
              </div>
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                Dr.
              </label>
              <input
                type="text"
                className="mt-1 w-1/3 border rounded px-3 py-2 lower-dr-class"
                // onChange={(e) => this.setState({ drNameFirst: e.target.value })}
                value={patientInfo?.PhysicianName4}
                disabled
              />{" "}
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                or his assigns such sums as are claimed by said{" "}
              </label>
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              {" "}
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                Dr.
              </label>{" "}
              <input
                type="text"
                className="mt-1 w-1/3 border rounded px-3 py-2 lower-dr-class"
                // onChange={(e) => this.setState({ drNameSecond: e.target.value })}
                value={patientInfo?.PhysicianName5}
                disabled
              />{" "}
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                or his assigns such medical care and services as herein before
                referred to. IN WITNESS WHEREOF,{" "}
              </label>
            </div>

            <div className="sm:col-span-4 lg:space-x-4">
              <label
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="font-bold"
              >
                I have hereunto set my hand this
              </label>{" "}
              <input
                type="text"
                className="border rounded px-3 py-2 md:w-64 lg:w-64 my-hand-class"
                value={patientInfo?.HereOntuDate}
                // onChange={(e) => this.setState({ setMyHandDate: e.target.value })}
                disabled
              />
            </div>

            <div className="sm:col-span-4 space-x-4">
              <p
                style={{ fontSize: 13, color: "rgb(95 94 94)" }}
                className="text-center font-bold"
              >
                Please acknowledge your acceptance of the lien by signing below
                and returning to me:
              </p>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            {/* Name of Patient */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-center sm:gap-4">
              <label
                htmlFor="first-name"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Name Of Patient:
              </label>
              <input
                type="text"
                disabled
                className="w-full sm:flex-grow p-2 border border-gray-300 rounded signdata-custom"
                value={patientInfo?.PatientName1}
              />
            </div>
          
            {/* Name of Attorney */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-center sm:gap-4">
              <label
                htmlFor="last-name"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Name Of Attorney:
              </label>
              <input
                type="text"
                disabled
                className="w-full sm:flex-grow p-2 border border-gray-300 rounded signdata-custom"
                value={
                  patientInfo?.AttorneyName
                    ? patientInfo?.AttorneyName
                    : validateAttorneyData?.AttorneyName
                }
              />
            </div>
          
            {/* Signature of Patient */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-center sm:gap-4">
              <label
                htmlFor="patient-signature"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Signature of Patient:
              </label>
              <div
                className="w-64 h-36 border border-green-500 flex items-center justify-center cursor-not-allowed signdata-custom"
                disabled
              >
                {patientInfo?.PatientSign && (
                  <img
                    src={"data:image/png;base64," + patientInfo?.PatientSign}
                    alt="Signature"
                    style={{height:"100%"}}
                  />
                )}
              </div>
            </div>
          
            {/* Signature of Attorney */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-center sm:gap-4">
              <label
                htmlFor="attorney-signature"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Signature Of Attorney:
              </label>
              <div
                className="w-64 h-36 border border-green-500 flex items-center justify-center cursor-pointer signdata-custom"
                onClick={(e) => handleOpenSignaturePad(e)}
              >
                {(patientInfo?.AttorneySign || attorneySignImg) && (
                  <img
                    src={
                      attorneySignImg
                        ? attorneySignImg
                        : "data:image/png;base64," + patientInfo?.AttorneySign
                    }
                    style={{height:"100%"}}
                    alt="Signature"
                  />
                )}
              </div>
            </div>
          
            {/* Date of Signature */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-center sm:gap-4">
              <label
                htmlFor="signature-date"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Date of Signature:
              </label>
              <input
                type="text"
                disabled
                className="w-full sm:flex-grow p-2 border border-gray-300 rounded signdata-custom"
                value={patientInfo?.PatientSignDate}
              />
            </div>
          
            {/* Address of Attorney */}
            <div className="sm:col-span-3 flex flex-col sm:flex-row sm:items-start sm:gap-4">
              <label
                htmlFor="attorney-address"
                className="block text-sm font-bold mb-2 sm:mb-0"
                style={{ fontSize: 13, color: "rgb(95, 94, 94)" }}
              >
                Address of Attorney:
              </label>
              <textarea
                className="w-full sm:flex-grow p-2 border border-gray-300 rounded signdata-custom"
                value={
                  patientInfo?.AttorneyAddress
                    ? patientInfo?.AttorneyAddress
                    : validateAttorneyData?.AttorneyAddress
                }
                disabled
              />
            </div>
          </div>
          

          
          </div>
        </form>
      </div>

      <div className="col-span-2 text-center sticky top-0 z-19 mb-5">
        <button
          type="submit"
          style={{ fontSize: 13 }}
          className="mt-3 px-6 py-2 bg-cadetblue text-white font-bold rounded hover:bg-cadetblue focus:outline-none"
          onClick={() => onSubmit()}
        >
          Submit
        </button>
      </div>

      <div className={'w-full text-center text-gray-600 text-sm  py-1 z-19 border-t-2'}>
          Copyright © {new Date().getFullYear()} GYB All Rights Reserved.
      </div>  
      </>
    }   
    
      {showSign && (
        <SignatureComponent
          onSave={(attorneySign) => onSave(attorneySign)}
          openModal={showSign}
          closeSignPad={() => closeSignPad()}
          signatureImage={
            attorneySignImg
              ? attorneySignImg
              : "data:image/png;base64," + patientInfo?.AttorneySign
          }
        />
      )}
    </>
  );
};

export default AttorneySign;
