// src/components/PublicRoute.js
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { getLocalStorage } from 'services/storage';
import * as keys from "utils/constant";

const PublicRoute = ({ children }) => {
  const isAuthenticated = getLocalStorage(keys.STORAGE_AUTH_KEY);
  const location = useLocation();

  // Check if the current route is `attorneySign`
  const isAttorneySignRoute = location.pathname === "/attorneySign";

  return !isAuthenticated || isAttorneySignRoute ? (
    <>
      <div className="content-container">
        {children}
      </div>
    </>
  ) : (
    <Navigate to="/patientList" />
  );
};

export default PublicRoute;