import {
  getFromCommmonService,
  GetResponseBlob,
  GetResponseBlobPromise,
  postToCommonService,
  postWithFileToCommonService,
  post,
  postWithoutTOken
} from "../apiUtils";

const baseURL1 = process.env.REACT_APP_CASE_AND_VISIT_API_URL

const baseURL = process.env.REACT_APP_API_URL
const baseURL2 = process.env.REACT_APP_CASE_AND_VISIT_API_URL

export const getPatientList = async (searchData) => {
  const queryString = Object.keys(searchData)
    .filter(key => searchData[key] !== undefined && searchData[key] !== null && searchData[key] !== false && searchData[key] !== "" && searchData[key].length !== 0)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(searchData[key])}`)
    .join('&');
  return new Promise((resolve, reject) => {
    getFromCommmonService(
      `${baseURL2}Attorney/GetPatientAttorneyReport?` + queryString,
      "",

      false
    )
      .then((response) => {
        return resolve({ success: true, response: response });
      })
      .catch((error) => {
        return reject({ success: false, response: error });
      });
  });
};

export const getVisitList = async (searchData) => {
  const queryString = Object.keys(searchData)
    .filter(key => searchData[key] !== undefined && searchData[key] !== null && searchData[key] !== false && searchData[key] !== "" && searchData[key].length !== 0)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(searchData[key])}`)
    .join('&');
  return new Promise((resolve, reject) => {
    getFromCommmonService(
      `${baseURL}Report/PatientReferralReport?` + queryString,
      "",
      false
    )
      .then((response) => {
        return resolve({ success: true, response: response });
      })
      .catch((error) => {
        return reject({ success: false, response: error });
      });
  });
};

export const getPatientInfo = async (data) => {

  return new Promise((resolve, reject) => {
    getFromCommmonService(
      `${baseURL1}Case/GetPatientDetailByCaseId?caseNo=${data.caseId}&companyId=${data.companyId}`,
      "",
      false
    )
      .then((response) => {
        return resolve({ success: true, response: response });
      })
      .catch((error) => {
        return reject({ success: false, response: error });
      });
  });
};


export const GetDocumentManagerNodeList = async (data) => {
  return await getFromCommmonService(
    process.env.REACT_APP_COMMON_API_URL +
    `Document/GetDocumentNodes/?CaseNo=${data.caseNo}&UserUid=${data.userUId}&CompanyId=${data.companyId}`,
    "",
    false,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      const errorMessage = error?.receiveObj?.response?.data?.message;
      if (errorMessage) {
        // notificationService.sendErrorMessage(errorMessage);
      }
      return error?.receiveObj?.response?.status;
    });
};

export const GetContactList = async (companyId) => {
  return await getFromCommmonService(
    process.env.REACT_APP_COMMON_API_URL +
    `Document/GetAllContactsData?CompanyId=${companyId}`,
    "",
    false,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      const errorMessage = error?.receiveObj?.response?.data?.message;
      if (errorMessage) {
        // notificationService.sendErrorMessage(errorMessage);
      }
      return error?.receiveObj?.response?.status;
    });
};

export const GetEmailBody = async (patientName, userUID) => {
  return await getFromCommmonService(
    process.env.REACT_APP_COMMON_API_URL +
    `Document/GetEmailDefaultTemplate?PatientName=${patientName}&UserUid=${userUID}`,
    "",
    false,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      const errorMessage = error?.receiveObj?.response?.data?.message;
      if (errorMessage) {
        // notificationService.sendErrorMessage(errorMessage);
      }
      return error?.receiveObj?.response?.status;
    });
};

export const getFile = async (url) => {
  return await GetResponseBlob(
    process.env.REACT_APP_COMMON_API_URL + `${url}`,
    "",
    false,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data.arrayBuffer();
      }
    })
    .catch((error) => {
      const errorMessage = error?.receiveObj?.response?.data?.message;
      if (errorMessage) {
        // notificationService.sendErrorMessage(errorMessage);
      }
      return error?.receiveObj?.response.status;
    });
};

export const getFilesAsync = async (urls) => {
  let data = await Promise.all(
    urls.map((fileUrl) => {
      return GetResponseBlobPromise(
        process.env.REACT_APP_COMMON_API_URL + `${fileUrl}`,
        "",
        false,
        false
      );
    })
  );
  return data.map((result) => (result.status === 200 ? result?.data : null));
};

export const SendEmail = async (data) => {
  return await postWithFileToCommonService(
    process.env.REACT_APP_COMMON_API_URL + `Document/SendEmailGB`,
    "",
    data,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      //const errorMessage = error?.receiveObj?.response?.data?.message;
      // if (errorMessage) {
      //   notificationService.sendErrorMessage(errorMessage);
      // }
      return error?.receiveObj?.response?.status;
    });
};

export const SendFax = async (data) => {
  return await postWithFileToCommonService(
    process.env.REACT_APP_COMMON_API_URL + `Document/SendDirectFax`,
    "",
    data,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      //const errorMessage = error?.receiveObj?.response?.data?.message;
      // if (errorMessage) {
      //   notificationService.sendErrorMessage(errorMessage);
      // }
      return error?.receiveObj?.response?.status;
    });
};

export const UploadFile = async (file) => {
  return await postToCommonService(
    process.env.REACT_APP_COMMON_API_URL + `Document/Upload`,
    "",
    file,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data?.data;
      }
    })
    .catch((error) => {
      return error?.receiveObj?.response?.status;
    });
};

export const GetMedicallLien = async(payload)=>{
  return await postWithoutTOken(
    process.env.REACT_APP_GYB_WEB_API_URL + `GenericForm/CaseFormListData`,
    "",
    payload,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data;
      }
    })
    .catch((error) => {
      return error?.receiveObj?.response?.status;
    });

}

export const SaveMedicallLien = async(payload)=>{
  let data = [];
await fetch(process.env.REACT_APP_GYB_WEB_API_URL + "GenericForm/PrintCaseData", {
  method: "post",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify(payload),
})
  .then((Response) => {
    if (Response.status === 401) {
      data = "";
      return data;
    }
    return Response.blob();
  })
  .then((result) => {
    data = result
   
  });
  return data;
}


// export const SaveMedicallLien1 = async(payload)=>{
//   return await postfile(
//     process.env.REACT_APP_GYB_WEB_API_URL + `GenericForm/PrintCaseData`,
//     "",
//     payload,
//     false
//   )
//     .then((response) => {
//       if (response.status === 401) {
//         let data = "";
//         return data;
//       }
//       console.log(response)
//       return response.blob();
//     })
//     .catch((error) => {
//       return error?.receiveObj?.response?.status;
//     });

// }

export const UploadSign = async (data) => {
  return await post(
    process.env.REACT_APP_GYB_WEB_API_URL + `/GenericForm/SaveBulkAttorneySignature`,
    "",
    data,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data;
      }
    })
    .catch((error) => {
      return error?.receiveObj?.response?.status;
    });
};