import { getAsync, postAsync } from '../apiUtils'
  
  const baseURL1 = process.env.REACT_APP_CASE_AND_VISIT_API_URL
  const baseURL = process.env.REACT_APP_API_URL
  const baseURL2 = process.env.REACT_APP_CASE_AND_VISIT_API_URL
  const baseURL3 = process.env.REACT_APP_GYB_WEB_API_URL

export const ValidateAttorneyRequestToken = async (token) => {
  return await postAsync(
    `${baseURL3}GenericForm/ValidateAttorneyRequestToken?Token=${token}`,
    "",
    false,
    false
  )
    .then((response) => {
      if (response.success) {
        return response?.receiveObj?.data;
      }
    })
    .catch((error) => {
      const errorMessage = error?.receiveObj?.data?.message;
      if (errorMessage) {
        // notificationService.sendErrorMessage(errorMessage);
      }
      return error?.receiveObj?.response?.status;
    });
};